import $ from "jquery";
import createSortable from "@/utils/sortable";
import isTouchDevice from "@/utils/polyfills";

document.addEventListener("turbo:load", function() {
  //////////// Desktop section below here ///////////

  if (!isTouchDevice() && $(".js-flip-icon").length > 0) {
    // All the functions inside this if statement will be executed only on
    // non-touchscreen devices

    // console.log("Working with desktop js on Collection page")

    // flip when you click the icon
    $(".js-flip-icon").click(function() {
      var icon = $(this);
      flipWithIcon(icon);
    });

    // drag & drop coins in your collection
    if ($("#draggableSection").length > 0) {
      startCollectionSorter();
    }
  } // closes the if-desktop && Collection section

  //////////// Touch section below here ///////////

  if (isTouchDevice() && $(".js-flip-icon").length > 0) {
    // console.log("Working with touch js on Collection page")

    if ($(".touchFlip").length > 0) {
      // Check if pointer events are supported.
      if (window.PointerEvent) {
        // Add Pointer Event Listener
        // detectPointerEventSwipes();
        // ^^ This is for future development. According to https://developers.google.com/web/fundamentals/design-and-ux/input/touch/#add_event_listeners
        // , pointerevents are preferred over touchevents for Chrome.
        // For now we will just use TouchEvents.
        detectTouchEventSwipes();
      } else {
        // Add Touch Listener
        detectTouchEventSwipes();
      }
    } // close the if .touchFlip present?
  } // closes the if-touchscreen && Collection section
}); // close turbo:load

////////////////////////////////////
// Utility functions below here  ///
////////////////////////////////////

// Flip with the icon.
function flipWithIcon(icon) {
  // console.log("Flipping with icon");
  var frontOrBack = icon.parent().data("front-or-back");
  if (frontOrBack == "front") {
    icon.siblings(".vertical-flip-container").addClass("flop");
    icon.parent().data("front-or-back", "back");
    icon
      .children(".js-chevron")
      .html("<span uk-icon='icon: chevron-down; ratio: 2'></span>");
  } else if (frontOrBack == "back") {
    icon.siblings(".vertical-flip-container").removeClass("flop");
    icon.parent().data("front-or-back", "front");
    icon
      .children(".js-chevron")
      .html("<span uk-icon='icon: chevron-up; ratio: 2'></span>");
  }
}

// Detect left or right swipes; throw away up/down swipes.
function detectTouchEventSwipes() {
  $(".touchFlip").each(function() {
    this.addEventListener("touchstart", handleTouchStart, false);
    this.addEventListener("touchmove", handleTouchMove, false);

    var xDown = null;
    var yDown = null;

    function getTouches(evt) {
      return (
        evt.touches || evt.originalEvent.touches // browser API
      ); // jQuery
    }

    function handleTouchStart(evt) {
      xDown = getTouches(evt)[0].clientX;
      yDown = getTouches(evt)[0].clientY;
    }

    function handleTouchMove(evt) {
      if (!xDown || !yDown) {
        return;
      }

      var xUp = evt.touches[0].clientX;
      var yUp = evt.touches[0].clientY;

      var xDiff = xDown - xUp;
      var yDiff = yDown - yUp;

      if (Math.abs(xDiff) > Math.abs(yDiff)) {
        /*most significant*/
        if (xDiff > 0) {
          /* left swipe */
          // console.log("It was a left swipe!")
          event.preventDefault();
          flipWithTouch($(this));
        } else {
          /* right swipe */
          // console.log("It was a right swipe!")
          event.preventDefault();
          flipWithTouch($(this));
        }
      } else {
        if (yDiff > 0) {
          /* up swipe */
          // console.log("It was an up swipe!")
        } else {
          /* down swipe */
          // console.log("It was a down swipe!")
        }
      }
      /* reset values */
      xDown = null;
      yDown = null;
    }
  });
} // close the function detectTouchEventSwipes

function detectPointerEventSwipes() {
  // for future development
} // close the function detectPointerEventSwipes

function startCollectionSorter() {
  var sortable = createSortable(draggableSection, {
    onUpdate: function(evt) {
      var ourCoin = $(evt.item)
      var collectionCoinId = evt.item.dataset.id;
      var collectionNewPosition = evt.newIndex;

      $.ajax({
        url: $("#draggableSection").data("url"),
        type: "PATCH",
        dataType: "script",
        data: {
          collectionCoinId: collectionCoinId,
          newCollectionPosition: collectionNewPosition
        }
      });
    }
  });
}

// Flip when you swipe
function flipWithTouch(coin) {
  var frontOrBack = coin.data("front-or-back");
  if (frontOrBack == "front") {
    coin.find(".vertical-flip-container").addClass("flop");
    coin.data("front-or-back", "back");
  } else if (frontOrBack == "back") {
    coin.find(".vertical-flip-container").removeClass("flop");
    coin.data("front-or-back", "front");
  }
}
