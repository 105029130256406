export const SPECIAL_KEYS = {
  SPACE: "{space}",
  CLOSE: "{close}",
  LANGS: "{langs}",
  SHIFT: "{shift}",
  DEL: "{delete}",
};

export const SPECIAL_LAYOUTS = {
  LANGS: "langs",
};

export const DEFAULT_REPLACES = {
  [SPECIAL_KEYS.CLOSE]: "esc",
  [SPECIAL_KEYS.LANGS]: "langs",
  [SPECIAL_KEYS.DEL]: "del",
};

export const LANG_LAYOUT_REGEX = /{layout:.+}/;

const buildRow = (row, name, index, hasShifted, isShifted, isLast) => {
  let str = row.join(" ");

  if (index === 0) {
    str = `${SPECIAL_KEYS.CLOSE} ${str}`;
  }

  if (index === 1) {
    str = `${SPECIAL_KEYS.LANGS} ${str}`;
  }

  if (hasShifted && isLast) {
    const key = isShifted ? SPECIAL_KEYS.SHIFT : name;
    str = `${key} ${str}`;

    if (!isShifted) display[name] = "shift";
  }

  return [str, display];
};

const getLayout = (data, shift, hasDel) => {
  const layout = [];

  for (let i = 0; i < data.length; i++) {
    let str = data[i].join(" ");

    if (i === 0) {
      str = `${SPECIAL_KEYS.CLOSE} ${str}`;

      if (hasDel) {
        str += ` ${SPECIAL_KEYS.DEL}`;
      }
    }

    if (i === 1) {
      str = `${SPECIAL_KEYS.LANGS} ${str}`;
    }

    if (shift && i === data.length - 1) {
      str = `${shift} ${str}`;
    }

    layout.push(str);
  }

  layout.push(SPECIAL_KEYS.SPACE);

  return layout;
};

/*
  interface lang {
    title: string;
    layout: Array<string[]>;
    shifted?: Array<string[]>;
  }

  type langs = lang[];
*/
export const getLayoutSettings = (langs, hasDelete, defaultLayout) => {
  const layout = {};
  const display = { ...DEFAULT_REPLACES };
  let defaultLayoutName = SPECIAL_LAYOUTS.LANGS;
  const langsLayout = [];

  for (let i = 0; i < langs.length; i++) {
    const lang = langs[i];
    const hasShifted = Boolean(lang.shifted);
    const layoutName = `{layout:${i}}`;
    const shiftedlayoutName = `{layout:${i}:shift}`;

    const localLayout = getLayout(
      lang.layout,
      hasShifted ? shiftedlayoutName : null,
      hasDelete
    );

    if (hasShifted) {
      const shiftedLocalLayout = getLayout(
        lang.shifted,
        SPECIAL_KEYS.SHIFT,
        hasDelete
      );

      display[shiftedlayoutName] = "shift";
      layout[shiftedlayoutName] = shiftedLocalLayout;
    }

    display[layoutName] = lang.title;
    layout[layoutName] = localLayout;
    langsLayout.push(layoutName);
  }

  layout[SPECIAL_LAYOUTS.LANGS] = langsLayout;

  if (layout[defaultLayout]) defaultLayoutName = defaultLayout;

  return {
    layoutName: defaultLayoutName,
    layout,
    display,
  };
};

export const isDel = (symbol) => symbol === SPECIAL_KEYS.DEL;

export const isClose = (symbol) => symbol === SPECIAL_KEYS.CLOSE;

export const isShift = (symbol) => symbol === SPECIAL_KEYS.SHIFT;

export const isLayout = (symbol) =>
  symbol === SPECIAL_KEYS.LANGS || LANG_LAYOUT_REGEX.test(symbol);
