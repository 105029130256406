import { Controller } from "stimulus";
import $ from "jquery";

export default class FlipPreviewController extends Controller {
  initialize(){
  }

  shiftToLargeFlipPreview() {
    $('#js-flip-pane').addClass('large-flip-pane').removeClass('small-flip-pane');
    $('#js-flip-size').addClass('large-flip').removeClass('small-flip');
    $('#js-flip-height-setter').addClass('large-height-setter').removeClass('small-height-setter');
    $('#js-flip-attribution').addClass('large-attribution').removeClass('small-attribution');
  }

  shiftToSmallFlipPreview() {
    $('#js-flip-pane').addClass('small-flip-pane').removeClass('large-flip-pane');
    $('#js-flip-size').addClass('small-flip').removeClass('large-flip');
    $('#js-flip-height-setter').addClass('small-height-setter').removeClass('large-height-setter');
    $('#js-flip-attribution').addClass('small-attribution').removeClass('large-attribution');
  }


}
