import * as Uppy from "@/vendor/uppy";

export function uppyDashboard({
  id,
  restrictions,
  target,
  height,
  destination,
  invalidFileType,
  onUploadSuccess,
  locale: {
    youCanOnlyUploadFileTypes,
  }
}) {
  const uppy = Uppy.Core({
      id,
      restrictions,
      debug: true,
      autoProceed: true,
      allowMultipleUploads: false,
      locale: {
        strings: {
          youCanOnlyUploadFileTypes,
        }
      }
    })
    .use(Uppy.Dashboard, {
      target,
      inline: true,
      height,
      theme: 'dark',
    })

  if (destination == 's3') {
    uppy.use(Uppy.AwsS3, {
      companionUrl: '/', // will use /s3/params
    });
  } else if (destination == 's3_multipart') {
    uppy.use(Uppy.AwsS3Multipart, {
      companionUrl: '/', // will use /s3/multipart/*
    });
  } else {
    uppy.use(Uppy.XHRUpload, {
      endpoint: "/upload",
      limit: 1,
    });
  }

  uppy.on('upload-success', (file, response) => {
    onUploadSuccess(file, fileData(file, response, destination));
  })

  return uppy;
}

function fileData(file, response, destination) {
  if (destination == 's3') {
    return {
      id: file.meta["key"].match(/^cache\/(.+)/)[1], // object key without prefix
      storage: "cache",
      metadata: {
        size: file.size,
        filename: file.name,
        mime_type: file.type
      }
    };
  } else if (destination == 's3_multipart') {
    return {
      id: response.uploadURL.match(/\/cache\/([^\?]+)/)[1], // extract key without prefix
      storage: 'cache',
      metadata: {
        size: file.size,
        filename: file.name,
        mime_type: file.type,
      }
    };
  } else {
    return response.body;
  }
}
