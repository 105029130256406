import { Controller } from "stimulus";
import $ from "jquery";
import MediumEditor from "medium-editor";

export default class MediumEditorController extends Controller {
  static targets = []

  static all_medium_editors = null;

  initialize() {
    this.activateMediumEditor();
  }

  activateMediumEditor() {
    this.clearOldEditors();
    this.initializeNewEditor();
    this.bindTheEditors();
  }

  clearOldEditors() {
    if (MediumEditorController.all_medium_editors != null) {
      MediumEditorController.all_medium_editors.destroy();
      MediumEditorController.all_medium_editors = null;
    }
  }

  initializeNewEditor() {
    MediumEditorController.all_medium_editors = new MediumEditor(".editable",
      {
        toolbar: {
          buttons: ["bold", "italic", "underline", "anchor", "removeFormat"]
        },
        imageDragging: false,
        extensions: {
          "imageDragging": {}
        },
        targetBlank: true,
        anchor: {
          linkValidation: true
        }
      }
    ).subscribe("editableClick", function(e) {
      if (e.target.href) { window.open(e.target.href ) }
    })
  }

  bindTheEditors() {
    $(".editable").bind("input propertychange", function() {
      $("#coin_" + $(this).attr("data-field-id")).val($(this).html());
    });
  };
}
