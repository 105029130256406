import { Controller } from "stimulus";
import UIkit from "uikit";

export default class ModalController extends Controller {
  initialize() {
    this.cleanupPrevModals();
    this.showCurrentModal();
  }

  cleanupPrevModals() {
    document.querySelectorAll(".uk-modal").forEach((element) => {
      UIkit.modal(element).hide();
      element.remove();
    });
  }

  showCurrentModal() {
    UIkit.modal(this.element).show();
  }
}
