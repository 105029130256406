// TODO: migrate out of feature sniffing as it is deprecated.
//       Do not extend this check as it will break on computers with touchscreens.
function isTouchDevice() {
  if ('ontouchstart' in window || (window.DocumentTouch && document instanceof DocumentTouch)) {
    return true;
  }
  return false;
}

export default isTouchDevice;
