import { Controller } from "stimulus";
import UIkit from "uikit";

export default class FlashController extends Controller {
  static targets = [ "flashy" ]

  initialize(){
    this.makeFlash();
  }

  makeFlash() {
    // find the dom element with data-target. The .flashyTarget is a Stimulus magic word.
    const element = this.flashyTarget
    
    var icon = element.dataset.icon
    var name = element.dataset.name
    var msg = element.dataset.msg

    UIkit.notification({
      message: icon + msg,
      status: name,
      pos: 'top-right',
      timeout: 3000
    })

  }

}
