import { Controller } from "stimulus";

export default class SearchController extends Controller {
  static targets = ["query"];

  typeahead() {
    clearTimeout(this.timeout);

    this.timeout = setTimeout(() => {
      if (this.queryTarget.value) {
        this.element.requestSubmit();
      } else {
        this.reset();
      }
    }, 500);
  }

  reset() {
    this.queryTarget.setAttribute("value", "");
    document.querySelector("#typeahead_search_results").innerHTML = "";
  }
}
