import { Controller } from "stimulus";
import $ from "jquery";
import createSortable from "@/utils/sortable";

export default class CatSortController extends Controller {
  static targets = []

  initialize(){
    this.startCatalogueSorter();
  }

  startCatalogueSorter() {
    createSortable(draggableCatalogueTwo, {
      onUpdate: function(evt) {
        var coinId = evt.item.dataset.id;
        var coinNewPosition = evt.newDraggableIndex + 1;

        $.ajax({
          url: $("#draggableCatalogueTwo").data("url"),
          type: "POST",
          dataType: "script",
          data: {
            id: coinId,
            direction: "drag",
            newCataloguePosition: coinNewPosition
          },
          success: function(data) {
          }
        });
      }
    });
  }

  moveup(event) {
    var currentCoin = event.target.closest("tr")
    moveCoin(currentCoin, "up")
  }

  movedown(event) {
    var currentCoin = event.target.closest("tr")
    moveCoin(currentCoin, "down")
  }

  moveCoin(currentCoin, direction) {
    var url = currentCoin.closest("table").dataset.url
    var coinId = currentCoin.dataset.id

    $.ajax({
      url: url,
      type: "POST",
      dataType: "json",
      data: {
        id: coinId,
        direction: direction
      },
      success: function(data) {
        var elem = $(currentCoin)

        if (direction == "up") {
          var prev = elem.prev()

          if (prev.length != 0) {
            elem.insertBefore(prev);
          }
        } else {
          var next = elem.next()

          if (next.length != 0) {
            elem.insertAfter(next);
          }
        }
      }
    });
  }
}
