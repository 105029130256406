import "@/vendor/jquery";
import "@/vendor/uikit";
import "@/vendor/medium-editor";

import "@hotwired/turbo-rails";
import "@turbo-boost/streams";
import "@rails/ujs";
import "trix";
import "@rails/actiontext";
import "chartkick/chart.js"

import "@/features/cabinet";
import "@/features/coins";
import "@/features/collections";
import "@/features/direct-uploads";

import "@/channels/cabinet";

import "@/serviceworker-companion";

import { Application } from "stimulus";
import { registerControllers } from "stimulus-vite-helpers";

const application = Application.start();
const controllers = import.meta.globEager("@/**/*_controller.js");
registerControllers(application, controllers);
