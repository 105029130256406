import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  connect() {
    
    const checkbox = document.getElementById('accept-terms-checkbox');
    const purchaseButton = document.getElementById('purchase-button');

    checkbox.addEventListener('change', function() {
        purchaseButton.disabled = !this.checked;
    });

    purchaseButton.addEventListener('click', function(event) {
        if (this.disabled) {
        event.preventDefault();
        UIkit.modal.alert('Please accept the Terms & Conditions before purchasing.');
        }
    });

  }
}