import { Controller } from "stimulus";
import $ from "jquery";

export default class ImageDownloadController extends Controller {
  initialize(){
    this.ensureCompatibility();
  }

  ensureCompatibility() {
    var transparencyInput = $('input:radio[value="scrubbed"]');
    var transparencyRequested = transparencyInput.is(':checked')
    var stitchedRequested = $('input:radio[value="stitched"]').is(':checked');

    if (stitchedRequested && transparencyRequested) {
      $('input:radio[value="basic"]').prop('checked', true);
    }

    transparencyInput.attr('disabled', stitchedRequested);
  }
}
