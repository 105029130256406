import { Controller } from "stimulus";
import $ from "jquery";

import "@/vendor/magnify";

export default class ZoomController extends Controller {
  static targets = [ ]

  initialize(){
    this.zoomOnCoin();
  }

  zoomOnCoin() {
    var $zoom = $(".zoom").magnify({
      speed: 200, // this is 10ms, essentially instantaneous.
      touchBottomOffset: 0,
      mobileCloseEvent: "click",
      limitBounds: false // Set this to true to keep the edge of the image within the magnifying lens.
    });
  }
}
