import { Controller } from "stimulus";
import tilt from "vanilla-tilt";

const ASYNC_DELAY = 24;

export default class TiltController extends Controller {
  initialize() {
    setTimeout(
      () =>
        tilt.init(this.targets.element, {
          max: 25,
          speed: 900,
          scale: 1.0,
          glare: false,
        }),
      ASYNC_DELAY
    );
  }
}
