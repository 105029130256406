import { Controller } from "stimulus";
import $ from "jquery";
import createSortable from "@/utils/sortable";

export default class CollectionEditController extends Controller {
  static targets = []

  initialize(){
    this.startCollectionSorter();
  }

  startCollectionSorter() {
    var sortable = createSortable(managedCollection, {
      onUpdate: function(evt) {
        var ourCoin = $(evt.item)
        var collectionCoinId = evt.item.dataset.id;
        var collectionNewPosition = evt.newIndex;

        $.ajax({
          url: $("#managedCollection").data("url"),
          type: "PATCH",
          dataType: "script",
          data: {
            collectionCoinId: collectionCoinId,
            newCollectionPosition: collectionNewPosition
          }
        });
      }
    }, true);
  }
}
