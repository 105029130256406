import { Controller } from "stimulus";
import Keyboard from "simple-keyboard";
import MediumEditor from "medium-editor";

import {
  SPECIAL_KEYS,
  SPECIAL_LAYOUTS,
  getLayoutSettings,
  isDel,
  isClose,
  isShift,
  isLayout,
} from "@/utils/keyboardLayout";
import { getItem, setItem } from "@/utils/localStorage";

import langs from "@/data/foreign_langs.json";

import "simple-keyboard/build/css/index.css";

const MOBILE_BREAKPOINT = 750;
const ANIMATION_DIRATION = 300;
const LOCAL_STORAGE_KEY = "foreign_langs_layout";

// pretty dummy
const isMobile = () => window.innerWidth < MOBILE_BREAKPOINT;

export default class KeyboardController extends Controller {
  isMobile = false;
  isOpened = false;
  editorNode = null;
  viewNode = null;
  mem = [];
  pasteButtonNode = null;
  keyboardNode = null;
  keyboardInstance = null;
  container = null;
  isClosing = false;

  initialize() {
    this.handlePaste = this.handlePaste.bind(this);
  }

  switchKeyboard(event) {
    event.preventDefault();

    if (this.isClosing) return;

    this.isMobile = isMobile();

    if (this.keyboardInstance) {
      this.handleClose();
    } else {
      const container = document.createElement("div");

      container.className = "simple-keyboard-wrapper";
      this.container = container;
      this.viewNode = document.createElement("p");
      this.viewNode.className = "simple-keyboard-view";
      this.container.appendChild(this.viewNode);
      [this.keyboardNode, this.keyboardInstance] = this.createKeyboard(
        this.isMobile
      );
      this.container.appendChild(this.keyboardNode);
      this.editorNode = event.currentTarget.parentNode.querySelector(
        ".editable"
      );

      document.body.appendChild(this.container);
      this.isOpened = true;
    }
  }

  showSwitcher() {
    if (this.editorNode) {
      this.editorNode.classList.add("keyboard-switch--show");
    }
  }

  hideSwitcher() {
    if (this.editorNode) {
      this.editorNode.classList.remove("keyboard-switch--show");
    }
  }

  handleFocus(event) {
    this.hideSwitcher();
    this.editorNode = event.target;
    this.showSwitcher();
    if (this.isMobile && this.isOpened) this.handleClose();
  }

  handlePaste() {
    if (this.mem.length && this.editorNode) {
      const editor = this.getEditor();
      editor.cleanPaste(this.mem.join(""));

      this.clearView();
      this.switchPasteButton(false);
    }
  }

  createKeyboard(isMobile) {
    const container = document.createElement("div");

    container.className = "simple-keyboard";

    const savedLayout = getItem(LOCAL_STORAGE_KEY);

    const layoutSettings = getLayoutSettings(langs, isMobile, savedLayout);

    const keyboard = new Keyboard(container, {
      mergeDisplay: true,
      buttonTheme: [
        {
          class: "simple-keyboard__button--highlighted",
          buttons: SPECIAL_KEYS.SHIFT,
        },
      ],
      theme: "hg-theme-default hg-layout-default simple-keyboard--dark",
      onKeyReleased: (button) => this.onKeyReleased(button),
      ...layoutSettings,
    });

    return [container, keyboard];
  }

  getEditor() {
    if (this.editorNode) {
      return MediumEditor.getEditorFromElement(this.editorNode);
    }

    return null;
  }

  onKeyReleased(symbol) {
    if (isShift(symbol)) return this.handleShift();
    if (isClose(symbol)) return this.handleClose();
    if (isDel(symbol)) return this.handleDel();
    if (isLayout(symbol)) return this.handleLayoutChange(symbol);

    let toPaste = symbol === SPECIAL_KEYS.SPACE ? " " : symbol;

    try {
      const savedContent = this.editorNode.innerHTML;

      if (this.isMobile) {
        this.mem.push(toPaste);
        this.viewNode.innerHTML = this.mem.join("");
        this.switchPasteButton(true);

        return;
      } else {
        const editor = this.getEditor();
        editor.cleanPaste(toPaste);
      }

      // In case we have not a focus...
      // so we check if anything changed after pasting try and if not just add symbol
      // to end of WYSIWYG editor
      // finally fire "input" event on editor to sync hidden form field
      if (savedContent === this.editorNode.innerHTML) {
        this.editorNode.innerHTML += toPaste;

        const fakeEvent = document.createEvent("Event");
        fakeEvent.initEvent("input", true, true);

        this.editorNode.dispatchEvent(fakeEvent);
      }
    } catch (e) {
      console.warn(e);
    }
  }

  handleShift() {
    const currentLayout = this.keyboardInstance.options.layoutName;

    this.keyboardInstance.setOptions({
      layoutName: currentLayout.replace(":shift", ""),
    });
  }

  handleLayoutChange(symbol) {
    let layoutName = SPECIAL_LAYOUTS.LANGS;

    if (symbol !== SPECIAL_KEYS.LANGS) {
      layoutName = symbol;
      setTimeout(() => setItem(LOCAL_STORAGE_KEY, symbol), 30);
    }

    this.keyboardInstance.setOptions({ layoutName });
  }

  handleDel() {
    // can be invoked only in case of mobile
    this.mem.pop();

    if (this.viewNode) {
      this.viewNode.innerHTML = this.mem.join("");
    }

    if (this.mem.length === 0) {
      this.switchPasteButton(false);
    }
  }

  handleClose() {
    if (!this.keyboardInstance) {
      return;
    }

    this.isClosing = true;
    this.container.classList.add("simple-keyboard-wrapper--closing");

    if (this.pasteButtonView) {
      this.pasteButtonView.innerHTML = this.mem.join("");
    }

    setTimeout(() => {
      this.keyboardInstance.destroy();
      this.keyboardNode.remove();
      this.keyboardInstance = null;
      this.keyboardNode = null;
      this.viewNode = null;
      this.container.remove();
      this.isClosing = false;
      this.isOpened = false;
    }, ANIMATION_DIRATION);
  }

  clearView() {
    this.mem = [];

    if (this.viewNode) {
      this.viewNode.innerHTML = "";
    }

    if (this.pasteButtonView) {
      this.pasteButtonView.innerHTML = "";
    }
  }

  switchPasteButton(show = false) {
    if (show) {
      if (this.pasteButtonNode) return;
      this.pasteButtonNode = document.createElement("button");
      this.pasteButtonNode.className =
        "simple-keyboard__paste uk-button uk-button-primary";
      this.pasteButtonNode.innerHTML = "Paste";
      this.pasteButtonNode.addEventListener("click", this.handlePaste);

      this.pasteButtonWrapper = document.createElement("div");
      this.pasteButtonWrapper.className = "simple-keyboard__paste-wrapper";

      this.pasteButtonView = document.createElement("p");
      this.pasteButtonView.className = "simple-keyboard__paste-view";

      this.pasteButtonWrapper.appendChild(this.pasteButtonView);
      this.pasteButtonWrapper.appendChild(this.pasteButtonNode);

      document.body.appendChild(this.pasteButtonWrapper);
    } else {
      if (!this.pasteButtonNode) return;
      this.pasteButtonNode.removeEventListener("click", this.handlePaste);
      this.pasteButtonWrapper.remove();
      this.pasteButtonNode = null;
      this.pasteButtonWrapper = null;
      this.pasteButtonView = null;
    }
  }
}
