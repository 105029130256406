import { Controller } from "stimulus";
import $ from "jquery";
import UIkit from "uikit";

export default class DocUploadController extends Controller {
  static targets = [ ]

  initialize(){
    this.doAnUpload();
  }

  doAnUpload() {

    var bar = document.getElementById('js-doc-progressbar');
    var docUrl = $("#jsDocUpload").data("upload-url");
    var aToken = $('meta[name="csrf-token"]').attr('content');


      UIkit.upload('#jsDocUpload', {

          url: docUrl,
          multiple: true,
          method: "PUT",
          name: "coin[documents][]",
          type: 'text',
          params: {
            authenticity_token: aToken
          },

          beforeSend: function (environment) {
              // console.log('beforeSend', arguments);

              // The environment object can still be modified here.
              // var {data, method, headers, xhr, responseType} = environment;

          },
          beforeAll: function () {
              // console.log('beforeAll', arguments);
          },
          load: function () {
              // console.log('load', arguments);
          },
          error: function () {
              // console.log('error', arguments);
          },
          complete: function () {
              // console.log('complete', arguments);
          },

          loadStart: function (e) {
              // console.log('loadStart', arguments);

              bar.removeAttribute('hidden');
              bar.max = e.total;
              bar.value = e.loaded;
          },

          progress: function (e) {
              // console.log('progress', arguments);

              bar.max = e.total;
              bar.value = e.loaded;
          },

          loadEnd: function (e) {
              // console.log('loadEnd', arguments);

              bar.max = e.total;
              bar.value = e.loaded;
          },

          completeAll: function () {
              // console.log('completeAll', arguments);

              setTimeout(function () {
                  bar.setAttribute('hidden', 'hidden');
              }, 1000);

              Turbo.renderStreamMessage(arguments[0].response);
          }
      });
  }
}
