export const getItem = (key) => {
  try {
    const item = window.localStorage.getItem(key);

    if (item) return JSON.parse(item);
  } catch (e) {
    console.warn(e);
  }

  return null;
};

export const setItem = (key, value) => {
  try {
    const stringifyedValue = JSON.stringify(value);

    window.localStorage.setItem(key, stringifyedValue);
  } catch (e) {
    console.warn(e);
  }
};
