import { Controller } from "stimulus";
import UIkit from "uikit";

export default class GrowingTextareaController extends Controller {
  initialize() {
    this.autogrowTextarea();
  }

  autogrowTextarea() {
    const tx = this.element.getElementsByTagName("textarea");
    for (let i = 0; i < tx.length; i++) {
      tx[i].setAttribute("style", "height:" + (tx[i].scrollHeight) + "px;overflow-y:hidden;");
      tx[i].addEventListener("input", function () {
        this.style.height = "auto";
        this.style.height = (this.scrollHeight) + "px";
      }, false);
    }
  }
}
