import Cropper from "@/vendor/cropper";

export function cropper({
  file,
  image,
  crop,
}) {
  const cropperInstance = new Cropper(image, {
    crop: (event) => { crop(event.detail) },
    dragMode: "move",
    zoomable: false,
    background: false,
    autoCropArea: 1.0,
    viewMode: 1
  });

  // Add custom styling to the grab points
  const addCustomGrabPoints = () => {
    // Wait for the Cropper to initialize
    setTimeout(() => {
      const cropBox = cropperInstance.cropBox;
      if (cropBox) {
        const allPoints = cropBox.querySelectorAll('.cropper-point');
        
        allPoints.forEach(point => {
          if (point) {
            point.style.backgroundColor = 'rgba(51, 153, 255, .5)';
            point.style.borderColor = 'rgba(51, 153, 255, .5)';
            
            const isCorner = point.classList.contains('point-nw') || 
                             point.classList.contains('point-ne') || 
                             point.classList.contains('point-sw') || 
                             point.classList.contains('point-se');
            const isEastWest = point.classList.contains('point-e') || 
                               point.classList.contains('point-w');
            
            if (window.innerWidth <= 890) {
              point.style.width = isCorner ? '20px' : '';
              point.style.height = isCorner ? '20px' : '';
            } else if (window.innerWidth <= 990) {
              point.style.width = isCorner ? '15px' : '';
              point.style.height = isCorner ? '15px' : '';
            } else if (window.innerWidth <= 1100) {
              point.style.width = isCorner ? '10px' : '';
              point.style.height = isCorner ? '10px' : '';
            } else {
              // For screens > 1100px
              if (isEastWest) {
                point.style.width = '15px';
                point.style.height = '20px';
              } else {
                point.style.width = '';
                point.style.height = '';
              }
            }
          }
        });
      }
    }, 100);
  };

  addCustomGrabPoints();

  // Update the grab point size on window resize
  window.addEventListener('resize', addCustomGrabPoints);

  return cropperInstance;
}