import { Controller } from "stimulus";
import $ from "jquery";

import imagesLoaded from "imagesloaded";

export default class CoinFlipperController extends Controller {
  flipper = null;

  initialize() {
    this.flipper = $(this.targets.element);

    imagesLoaded(
      this.flipper,
      {
        // doing this to make sure the height calcs are running on images that have actually downloaded
        // options... insert here from https://github.com/desandro/imagesloaded as needed
      },
      () => this.onLoaded()
    );
  }

  onLoaded() {
    const img = this.flipper.find("img").first();
    const frontSide = this.flipper.children(".front");
    const backSide = this.flipper.children(".back");
    const embeddedImageWidth = img.data("width");

    this.flipper.width(embeddedImageWidth);
    frontSide.width(embeddedImageWidth);
    backSide.width(embeddedImageWidth);

    const embeddedImageHeight = img.innerHeight();

    this.flipper.height(embeddedImageHeight);
    frontSide.height(embeddedImageHeight);
    backSide.height(embeddedImageHeight);
  }
}
