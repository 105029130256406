import { Controller } from "stimulus";
import $ from 'jquery';

export default class MirrorTextsController extends Controller {
  initialize() {
    this.mirrorTextsInEditors();
  }

  mirrorTextsInEditors() {
    $('#js-editable-flip-text').bind('input propertychange', () => {
      this.migrateTheFlipText();
    });

    $('#js-editable-flip-text').click(function() {
      if ($(this).text().includes("Configure your flip insert")) {
        $(this).html("");
      }
    })
  }

  migrateTheFlipText() {
    var replacementText = $("#js-editable-flip-text").html();
    $("#js-realtime-flip").html(replacementText);
  };
}
