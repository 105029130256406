import $ from "jquery";
import { createChannel } from "@/utils/cable";
import { post } from "@rails/request.js";

// TODO: migrate to real-time Turbo Streams.

document.addEventListener("turbo:load", function() {
  var cabinet_subscription = $(".js-subscribe-to-cabinet");

  if (cabinet_subscription.length > 0) {
    const cabinet_id = cabinet_subscription.data("cabinet-id");
    subscribe_cabinet(cabinet_id);
  }
});

function subscribe_cabinet(cabinet_id) {
  const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');

  createChannel(
    {
      channel: "CabinetChannel",
      cabinet_id: cabinet_id
    },
    {
      connected: function() {
        // Called when the subscription is ready for use on the server
        // console.log("Subscribed to my cabinet channel");
      },

      disconnected: function() {
        // Called when the subscription has been terminated by the server
        // console.log("Disconnected from my cabinet channel");
      },
      received: function(data) {
        switch (data["action"]) {
          case "promote_obv_rev":
            post(
              "/push_coin_after_promote/images", {
                body: { coin_id: data.coin_id },
                responseKind: "turbo-stream",
                headers: {
                  'X-CSRF-Token': csrfToken
                }
              },
            )
            break;
          case "video_upload_completed":
            post(
              "/push_coin_after_promote/video", {
                body: { coin_id: data.coin_id },
                responseKind: "turbo-stream",
                headers: {
                  'X-CSRF-Token': csrfToken
                }
              },
            )
            break;
          case "data_request_changed":
            $.ajax({
              url: "/" + cabinet_id + "/data_requests/new",
              type: "GET",
              dataType: "script",
              headers: {
                'X-CSRF-Token': csrfToken
              },
              success: function(data) {},
              error: function(data) {}
            });
            break;
          case "provenance_search_completed":
            $.ajax({
              url: "/push_provenance_after_search/provenance_search_complete",
              type: "POST",
              dataType: "script",
              data: { coin_id: JSON.stringify(data.coin_id) },
              headers: {
                'X-CSRF-Token': csrfToken
              },
              success: function(data) {},
              error: function(data) {}
            });
            break;
        }
      }
    }
  );
}
