import Sortable, { AutoScroll } from "sortablejs/modular/sortable.core.esm.js";
import isTouchDevice from "@/utils/polyfills";

Sortable.mount(new AutoScroll());

function createSortable(element, overrides, skipTouchDevices = false) {
  // TODO: change UI to migrate out of feature sniffing as we lose the ability to sort on tablets.
  if (skipTouchDevices && isTouchDevice()) {
    return;
  }

  const defaultOptions = {
    forceFallback: true, // NOTE: use a custom implementation for proper styling and scrolling.
    delay: 20, // NOTE: prevent sorting activation when clicking on elements while moving the mouse.
    animation: 500,
    ghostClass: "ghost",

    scroll: true,
    bubbleScroll: true,
    scrollSensitivity: 100,
    scrollSpeed: 20,
  };

  const options = Object.assign({}, defaultOptions, overrides);

  Sortable.create(element, options);
}

export default createSortable;
